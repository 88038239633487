import React, { useState } from 'react';
import '../App.css'; // Import the CSS file if you're using one

function JobForm({ onJobCreated }) {
  const [description, setDescription] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [createdBy, setCreatedBy] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const job = {
      description: description,
      hours: parseInt(hours, 10),
      minutes: parseInt(minutes, 10),
      created_by: createdBy,
    };

    fetch('/api/jobs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(job),
    }).then((response) => {
      if (response.ok) {
        onJobCreated();
        setDescription('');
        setHours(0);
        setMinutes(0);
      }
    });
  };

  return (
    <div className="container">

      <div className="created-by-field form-field">
        <label>Name:</label>
        <input
          type="text"
          value={createdBy}
          onChange={(e) => setCreatedBy(e.target.value)}
        />
      </div>

      <h2>New Job:</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label>Description:</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label>Hours:</label>
          <input
            type="number"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label>Minutes:</label>
          <input
            type="number"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            required
          />
        </div>

        <button type="submit">Add&nbsp;Job</button>
      </form>
    </div>
  );
}

export default JobForm;
